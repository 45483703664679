body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  font-size: smaller;
  color: red;
}

.testcase {
  height: 20px;
  padding: 5px;
  font-size: smaller;
  background: rgb(230,230,230);
  margin: 5px auto;
  width: 50%;
}
.testcase-header {
  height: 20px;
  padding: 5px;
  background: rgb(200,200,200);
  margin: 5px auto;
  width: 50%;
}

.passed {
  background: green;
}

.failed {
  background: red;
}

.w-test-number {
  box-sizing: border-box;
  width: 10%;
  float: left;
  padding-left: 30px;
  text-align: left;
}
.w-test-info {
  box-sizing: border-box;
  padding-left: 30px; 
  width: 70%;
  float: left;
  text-align: left;
}
.w-status {
  padding-right: 30px;
  box-sizing: border-box;
  float: right;
  text-align: left;
}
.clear {
  clear: both;
}

table {
  width: 100%;
  max-width: 100%;
  border-color: grey;
  background-color: transparent;
  box-sizing: border-box;
}