.log-container {
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40rem;
  margin: 4rem 0;
  overflow-x: scroll;
  overflow-y: scroll;
  overflow: scroll;
}

.log-item {
  animation: fadeInAnimation 1s linear;
  padding: 1rem;
}

.log-item__data {
  outline: none;
  border: none;
  padding: 0;
}

.dotted-line {
  border-top: dotted 1px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 8px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.clickable {
  cursor: pointer;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
